<template>
  <div>
    <h2 class="title is-3">My bookings</h2>
    <p>Find and manage all your current and previous bookings. Do you have questions about your reservation or any other questions? Please feel free to contact the location or leave a comment on the booking.</p>

    <hr />

    <div class="navbar-custom-menu">
      <div class="navbar-start">
        <div class="navbar-item">
          <a
            class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
            :class="{ 'is-active': pageState.statusId === 2 }"
            @click="setStatus(2)"
          >
            Final reservations</a>
        </div>
        <div class="navbar-item">
          <a
            class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
            :class="{ 'is-active': pageState.statusId === 1 }"
            @click="setStatus(1)"
          >
            Reservations in option</a>
        </div>
        <div class="navbar-item">
          <a
            class="navbar-link is-arrowless is-unselectable is-size-7-mobile"
            :class="{ 'is-active': pageState.statusId === 0 }"
            @click="setStatus(0)"
          >
            Cancelled reservations</a>
        </div>
      </div>
    </div>
    <hr />
    <div class="box is-shadowless search-wrapper mb-6 p-4 has-background-grey-lighter">
      <div class="columns is-multiline is-mobile">
        <div class="column is-half-mobile is-one-quarter-desktop">
          <label class="label">Bookings number or name</label>
          <input
            type="text"
            class="input"
            v-model="pageState.searchTerm"
          />
        </div>
        <div class="column is-half-mobile is-one-quarter-desktop">
          <div class="datepicker-trigger">
            <label class="label">From</label>
            <v-date-picker
              v-model="date"
              mode="date"
              :is-range="false"
            >
              <template v-slot="{ inputEvents }">
                <input
                  class="input"
                  type="text"
                  :value="$options.filters.dateFormat(date)"
                  v-on="inputEvents"
                  readonly
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="column">
          <label class="label">Show history</label>
          <div class="field is-inline-flex no-margin-bottom mr-6 has-addons">
            <p class="control">
              <button
                class="button"
                :class="{ 'is-primary': !pageState.showHistory, 'is-primary-light' : pageState.showHistory }"
                @click="pageState.showHistory = false"
              >
                <span>NO</span>
              </button>
            </p>
            <p class="control">
              <button
                class="button"
                :class="{ 'is-primary': pageState.showHistory, 'is-primary-light' : !pageState.showHistory }"
                @click="pageState.showHistory = true"
              >
                <span>YES</span>
              </button>
            </p>
          </div>
          <a
            @click="search"
            class="button-search button is-primary"
          >
            <span>Search</span>
          </a>
        </div>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <ui-loader
        key="loading"
        v-if="isLoading"
        class="has-padding has-text-centered"
      />
      <div
        v-else
        key="reservations"
      >
        <message
          v-if="!pageState.reservations.length"
          class="is-info"
        >
          {{ message }}
        </message>
        <div v-if="pageState.reservations.length">
          <ReservationListItem
            v-for="reservation in pageState.reservations"
            :key="'rli'+reservation.Id"
            :reservation="reservation"
          />
        </div>
      </div>
    </transition>

    <Pagination
      class="has-margin-top-x2"
      v-if="showPaging"
      :currenPage="pageState.page"
      :morePages="pageState.morePages"
      @pageSelected="updatePage"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ReservationListItem from '@/components/Reservation/ReservationListItem'
import Pagination from '../../components/UI/Pagination'
import reservationProvider from '../../providers/reservation'
import Message from '../UI/Message.vue'

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  name: 'reservationList',

  components: {
    ReservationListItem,
    Pagination,
    Message
  },

  data() {
    return {
      firstLoad: true,
      isLoading: false,
      meetingtypeId: 0,
      date: this.$options.filters.dateObjectIsoDateString(new Date()),
      pageState: this.$store.state.reservationStore.reservationPageState,
      statusId: 2,
      texts: {
        'Message_FoundStatusId2': 'No final reservations found.',
        'Message_FoundStatusId1': 'No reservations in option found.',
        'Message_FoundStatusId0': 'No cancelled reservations found.',
        'Message_NotFoundInPastStatusId2': 'No past final reservations found.',
        'Message_NotFoundInPastStatusId1': 'No past reservations in option found.',
        'Message_NotFoundInPastStatusId0': 'No past cancelled reservations found.',
      },
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    }),

    message() {
      return this.pageState.showHistory ? this.texts[`Message_NotFoundInPastStatusId${this.pageState.statusId}`] : this.texts[`Message_FoundStatusId${this.pageState.statusId}`]
    },

    showPaging() {
      return !this.firstLoad
        && !this.isLoading
        && (this.pageState.page !== 1
          || (this.pageState.page === 1 && this.pageState.morePages > 0)
        )
    }
  },

  watch: {
    date: {
      handler: function(val) {
        this.pageState.startDate = val
        this.pageState.endDate = val
      }
    },
  },

  created() {
    this.date = !this.pageState.showHistory ?
      this.$options.filters.dateObjectIsoDateString(this.pageState.startDate)
      :
      this.$options.filters.dateObjectIsoDateString(this.pageState.endDate)

    this.statusId = this.pageState.statusId
    this.getReservations()
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateOnlineStatus)
    this.isLoading = false

    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  methods: {
    ...mapMutations('reservationStore', [
      'setReservationsPageState',
    ]),

    setStatus(statusId) {
      this.statusId = statusId
      this.getReservations()
    },

    updatePage(page) {
      this.pageState.page = page
      this.getReservations()
    },

    async getReservations() {
      this.isLoading = true
      this.pageState.reservations = []

      this.pageState.statusId = this.statusId
      this.pageState.startDate = this.pageState.showHistory ? new Date(1900, 1, 1) : this.date
      this.pageState.endDate = !this.pageState.showHistory ? new Date(1900, 1, 1) : this.date

      reservationProvider.methods.getReservations({
        q: this.pageState.searchTerm,
        startDate: this.pageState.startDate,
        endDate: this.pageState.endDate,
        statusId: this.pageState.statusId,
        sortDirection: this.pageState.showHistory ? 'DESC' : 'ASC',
        page: this.pageState.page,
        itemsPerPage: this.pageState.itemsPerPage
      })
        .then(response => {
          this.pageState.morePages = response.data.MorePages
          this.pageState.reservations = response.data.Results
          this.setReservationsPageState(this.pageState)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
        .finally(() => {
          this.firstLoad = false
        })
    },

    search() {
      this.pageState.page = 1
      this.pageState.morePages = 0
      this.pageState.reservations = []
      this.getReservations()
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-custom-menu {
  align-items: stretch;

  .navbar-start {
    .navbar-item {
      @media screen and (max-width: $tablet - 1) {
        padding-left: 0.2em;
        padding-right: 0.2em;
      }
    }
  }
}

.search-wrapper {
  .column {
    @media screen and (max-width: 500px) {
      flex-basis: 100%;
      &:not(:first-child) {
        padding-top: 0;
      }
    }
  }
  .column:nth-child(3) {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1023px) {
      flex-basis: 100%;
      padding-top: 0;
    }
    label {
      width: 100%;
    }
    a.button-search {
      flex: 1 1;
      @media screen and (max-width: 1023px) {
        width: 50%;
      }
    }
  }
}
</style>
