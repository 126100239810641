<template>
  <div>
    <nav class="navbar-custom-menu">
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="icon-text navbar-link is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>

      <div
        v-for="(tab, index) in tabs"
        :key="'t'+index"
        class="navbar-item"
      >
        <a
          @click="tabIndex = index"
          class="navbar-link is-arrowless"
          :class="{ 'is-active' : tabIndex === index }"
        >
          <span v-text="tab"></span>
        </a>
      </div>
    </nav>
    <hr />
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <reservation-list
        v-if="tabIndex === 0"
        key="reservationList"
        class="contentWrapper"
      />
      <booking-widget
        v-if="tabIndex === 1"
        key="bookingWidget"
        :showResultInModal="false"
        titleClass="title is-3"
        class="contentWrapper padding has-background-white"
      />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BookingWidget from '../../components/BookingWidget.vue'
import ReservationList from '../../components/Reservation/ReservationList.vue'


export default {
  name: 'reservations',

  components: { ReservationList, BookingWidget },

  data() {
    return {
      tabIndex: 0
    }
  },

  computed: {
    ...mapState(['isOutdatedBrowser']),
    tabs() {
      let arr = ['My bookings']
      if (!this.isOutdatedBrowser) {
        arr.push('Make a new booking')
      }
      return arr
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
