<template>
  <article class="reservationWrapper media">
    <figure class="media-left is-hidden-mobile">
      <p class="image is-4by3 has-background-grey-light">
        <img
          src="@/assets/img/spacer4_3.gif"
          :style="{backgroundImage: `url(${$options.filters.getLocationImage(reservation.LocationImage, reservation.LocationId, 320)})`}"
        >
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <div class="reservation-detail">
          <div class="row is-flex">
            <div>
              <strong class="is-block">Reservation</strong>
              <router-link
                :to="{ name: 'reservation-detail', params: {'reservationKey': reservation.Key}}"
                v-text="reservation.Id"
              ></router-link>
            </div>
            <div v-if="reservation.StatusId === 3">
              <strong class="is-block">Status</strong>
              <span class="tag is-warning">Cancelled</span>
            </div>
            <div>
              <strong class="is-block">Type</strong>
              {{seatTypeLabel['type' + reservation.MeetingtypeId]}}
            </div>
            <div>
              <strong class="is-block">Name</strong>
              <span v-text="reservation.Name"></span>
            </div>
          </div>
          <div class="row is-flex">
            <div>
              <strong class="is-block">Location</strong>
              {{ reservation.LocationName }}
            </div>
            <div>
              <strong class="is-block">Date</strong>
              {{ reservation.StartDate | parseIsoDateStringToDate | longDateFormat }}
            </div>
            <div>
              <strong class="is-block">Time</strong>
              {{ reservation.StartMinutes | minutesToTime }} - {{ reservation.EndMinutes | minutesToTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-one-quarters has-text-right">
      <router-link
        :to="{ name: 'reservation-detail', params: {'reservationKey': reservation.Key}}"
        class="button is-primary is-hidden-mobile"
      ><span>View</span><span class="icon"><i class="fas fa-chevron-right"></i></span></router-link>
    </div>

    <!-- <div class="dateBar has-background-grey-light"><i class="far fa-calendar-alt"></i> {{ reservation.StartDate | parseIsoDateStringToDate | longDateFormat }} ({{seatTypeLabel['type' + reservation.MeetingtypeId]}})</div>
        <div class="columnsWrapper">
          <div class="is-three-quarters">
            <div class="row"><i class="far fa-file-alt"></i> {{ reservation.Id }}</div>
            <div class="row"><i class="far fa-file-alt"></i> {{ reservation.Name }}</div>
            <div class="row"><i class="far fa-clock"></i> {{ reservation.StartMinutes | minutesToTime }} - {{ reservation.EndMinutes | minutesToTime }}</div>
            <div class="row"><i class="fas fa-map-marker-alt"></i> {{ reservation.LocationName }}</div>
          </div>
          <div class="is-one-quarters has-text-right">
            <router-link
              :to="{ name: 'reservation-detail', params: {'reservationKey': reservation.Key}}"
              class="button is-primary is-hidden-mobile"
            ><span>View</span><span class="icon"><i class="fas fa-chevron-right"></i></span></router-link>
            <router-link
              :to="{ name: 'reservation-detail', params: {'reservationKey': reservation.Key}}"
              class="button is-primary is-hidden-tablet"
            ><span class="icon"><i class="fas fa-chevron-right"></i></span></router-link>
          </div>
        </div> -->
  </article>
</template>

<script>
export default {
  name: 'ReservationItem',

  props: {
    reservation: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      seatTypeLabel: {
        type1: 'Meetingspace',
        type2: 'Workspace'
      }
    }
  },

  methods: {
    viewReservation(reservation) {
      window.location.href = `${process.env.VUE_APP_S2M_BASE_URL}/reservation/${reservation.Id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.reservationWrapper {
  .reservation-detail {
    .row {
      margin-left: -15px;
      margin-top: -15px;
      margin-right: -15px;
      margin-bottom: 15px;
      flex-wrap: wrap;
      div {
        width: 33%;
        padding: 15px;
        padding-bottom: 0;

        @media screen and (max-width: 900px) {
          width: 50%;
        }

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }

  .image {
    width: 175px;
  }
}
</style>
