<template>
  <div
    class="box-widget"
    :class="[deviceTypeCssClass]"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <ui-loader
        v-if="!scriptsLoaded"
        key="loading"
      />
      <div
        v-if="scriptsLoaded"
        key="ready"
      >
        <div
          class="mb-3 has-text-weight-bold"
          :class="titleClass"
          v-text="$t('Components.BookingWidget.Main_Title')"
        ></div>

        <div class="has-text-right is-size-7 mb-3">
          <a
            @click="setLanguage('nl')"
            :class="{'has-text-grey': locale !== 'nl'}"
          >NL</a> |
          <a
            @click="setLanguage('en')"
            :class="{'has-text-grey': locale !== 'en'}"
          >EN</a>
        </div>

        <div ref="widgetHolder"></div>

        <c-lab-bookingwidget
          :widget-key="widgetKey"
          :show-result-in-modal="String(showResultInModal)"
          :locale="locale"
          class="is-block mb-4"
        ></c-lab-bookingwidget>

        <div class="usps columns is-multiline is-centered">
          <div
            v-for="(usp, index) in usps"
            :key="'u'+index"
            class="column is-narrow"
          >
            <div class="icon-text is-flex-wrap-nowrap">
              <span class="icon has-text-success">
                <font-awesome-icon :icon="['fas', 'check']" />
              </span>
              <span v-text="usp"></span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <link
      :href="widgetCssUrl"
      rel="stylesheet"
      type="text/css"
    />
  </div>
</template>

<script>
export default {
  name: 'BookingWidget',

  props: {
    /**
     * Pre set location ID
     */
    locationId: {
      type: Number,
      default: 0,
    },

    /**
     * Determine whether to show the wizard in and a modal or on page.
     */
    showResultInModal: {
      type: Boolean,
      default: true,
    },

    preSelectedMeetingType: {
      type: Number,
      default: 0
    },

    titleClass: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      ready: false,
      widgetKey: process.env.VUE_APP_SEARCH_WIDGET_KEY,
      selectedMeetingType: 0,
      locale: 'en',
      timestamp: new Date().getTime(),
      scriptLoadCounter: 0,
      scripts: [
        { type: 'script', url: process.env.VUE_APP_SEARCH_WIDGET_URL + '/app.js', indentifier: 'c-lab-b-script' },
      ],
      parentWidth: 0
    }
  },

  computed: {
    widgetCssUrl() {
      return process.env.VUE_APP_SEARCH_WIDGET_URL + '/app.css'
    },

    scriptsLoaded() {
      return this.scriptLoadCounter >= this.scripts.length
    },

    deviceTypeCssClass() {
      if (this.parentWidth >= 1024) {
        return 'is-desktop'
      }
      if (this.parentWidth >= 769) {
        return 'is-tablet'
      }
      return 'is-mobile'
    },

    usps() {
      let output = []
      switch (this.selectedMeetingType) {
        case 1:
          output = [
            this.t('Components.BookingWidget.Text_MS_USP1'),
            this.t('Components.BookingWidget.Text_MS_USP2'),
            this.t('Components.BookingWidget.Text_MS_USP3'),
          ]
          break
        case 2:
          output = [
            this.t('Components.BookingWidget.Text_WS_USP1'),
            this.t('Components.BookingWidget.Text_WS_USP2'),
          ]
          break
        default:
          output = []
          break
      }
      return output
    }
  },

  created() {
    document.addEventListener('cb-w-searchData', this.processWidgetData)

    this.scripts.forEach(data => {
      this.loadDynamicScript(data.type, data.url, data.indentifier, () => {
        this.scriptLoadCounter++
      })
    });
  },

  mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)

    this.scripts.forEach(data => {
      const elm = document.getElementById(data.indentifier)
      elm.parentNode.removeChild(elm);
    })
  },

  methods: {
    loadDynamicScript(type, url, indentifier, callback) {
      let headID = document.getElementsByTagName('head')[0];
      switch (type) {
        case 'script':
          const script = document.createElement('script')
          script.src = url
          script.type = 'text/javascript'
          script.id = indentifier
          script.async = true
          script.defer = true
          headID.appendChild(script)

          script.onload = () => {
            if (callback) {
              callback()
            }
          }
          break

        case 'style':
          const link = document.createElement('link')
          link.href = url
          link.id = indentifier
          link.type = 'text/css';
          link.ref = 'stylesheet'
          headID.appendChild(link)

          link.load = () => {
            if (callback) {
              callback()
            }
          }
          break
      }
    },

    setLanguage(language) {
      this.locale = language
    },

    processWidgetData(e) {
      if (!this.ready) {
        this.ready = true
      }

      if (this.selectedMeetingType === e.detail.MeetingtypeId) { return }

      this.selectedMeetingType = e.detail.MeetingtypeId
    },

    t(key) {
      var values = [], len = arguments.length - 1;
      while (len-- > 0) values[len] = arguments[len + 1];
      let i18n = this.$i18n;
      return i18n._t.apply(i18n, [key, this.locale, i18n._getMessages(), this].concat(values))
    },

    resizeHandler() {
      if (typeof this.$el === 'undefined') { return }
      let elmProps = this.$el.getBoundingClientRect()
      this.parentWidth = elmProps.width
    }
  },
}
</script>

<style lang="scss" scoped>
.is-mobile {
  .usps.columns {
    margin: -#{$gap / 2};
    justify-content: flex-start;
    .column {
      padding: #{$gap / 2};
      width: 100%;
      &:not(:last-child) {
        padding-bottom: 0;
      }
    }
  }
}
</style>